import React from 'react'
import NotFound1 from '../../src/Assets/images/error 404.gif'

export default function NotFound() {
  return (
    <div>
        <div className="section">
            <div className="container mt-5 pt-3 mb-5 d-flex align-items-center justify-content-center">

            <img src={NotFound1} className="w-50" />

            </div>
            </div>
    </div>
  )
}
