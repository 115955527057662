import React from 'react'
import partner_1 from '../../src/Assets/images/Rectangle 24.svg'
import partner_2 from '../../src/Assets/images/partenr2.svg'
import partner_3 from '../../src/Assets/images/partenr3.svg'
import partner_4 from '../../src/Assets/images/Rectangle 24 (3).svg'
import { useTranslation , Trans } from 'react-i18next';
export default function Partners() {
  return <>
         
         <div className="container">
                <div className="row justify-content-center align-items-center  mb-5">
                    <h2 className='fit-content'> <Trans i18nKey="partners">شركاؤنا </Trans> </h2>
                </div>
                <div className="row g-5">

                    <div className="col-md-3 d-flex justify-content-center">
                        <img src={partner_1} className="w-75"/>
                    </div>
                    <div className="col-md-3 d-flex justify-content-center">
                        <img src={partner_2} className="w-75"/>
                    </div>
                    <div className="col-md-3 d-flex justify-content-center">
                        <img src={partner_3} className="w-75"/>
                    </div>
                    <div className="col-md-3 d-flex justify-content-center">
                        <img src={partner_4} className="w-75"/>
                    </div>
                </div>

            </div>
  </>

}
