import React, { useEffect } from 'react'
import gifimg from '../../src/Assets/images/ezgif-1-64fc188f39.gif'
import { useTranslation , Trans } from 'react-i18next';
import WOW from 'wowjs';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';

export default function ContractCheck() {
    const { t , i18n} = useTranslation()

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
      }, []);


      const validationSchema = Yup.object({
        Name: Yup.string().required('هذا الحقل مطلوب '),
        Phone: Yup.string().required('هذا الحقل مطلوب '),
        Email: Yup.string().email('البريد الالكتروني المدخل غير صحيح').required('هذا الحقل مطلوب'),
      });
    
      const { values, handleSubmit, handleChange, errors, touched , handleBlur } = useFormik({
        initialValues: {
          code: "",
      
        },
        onSubmit: async (values, { setSubmitting }) => {
        //   setisloading(true)
    
          try {
            const response = await axios.post('http://5.189.146.89/BnanApi/api/CheckReceipt', values);
            console.log(response);
            // setsubmitted(true)
          } catch (error) {
            console.error(error);
          } finally {
            // setSubmitting(false);
          }
        //   setisloading(false)
    
        },
        validationSchema
    
      })
  return <>
   <section id="contract" className="contract section mt-5">
        <div className="container contract-contact-container">
            <div className="row  align-items-center pt-5 justify-content-center mt-5">
                <div className="col-md-7  p-5 contact-form-div  wow  fadeInUp"  data-wow-delay=".2s" >
                    <h1 className="fs-2  text-center"> <Trans  i18nKey="Verify"> تحقق من  المستند  </Trans>    </h1>
                    <form onSubmit={handleSubmit}> 
                       <div className="row gap-3  py-4 px-4 justify-content-center align-items-center">

                        <div className="col-lg-6">
                            <div className="row flex-column">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor= "code" className="form-label">  <Trans  i18nKey="contract-num">رقم المستند </Trans>   </label>
                                        <input type="text" className="form-control contract_input" id="code"  name="code"/>
                                      </div>
                                   </div>
                              </div>
                        </div>
                        <div className="col-lg-5">
                            <div className='gif_Img_Div' >  <img src={gifimg} className="w-100 gifimg" />
                            </div>
                        </div>


                       </div>
                       <div className="row pt-2 align-items-center justify-content-between px-4">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary search-button px-4 py-2">  <Trans  i18nKey="check"> تحقق </Trans>   </button>
                          </div>
                          <div className="col-md-4">
                            <div>pdf</div>
                          </div>
                      </div>
                      </form>
                 </div>
            </div>

        </div>

    </section>
</>
}
