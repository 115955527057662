import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { Outlet } from 'react-router-dom'

export default function Layout() {
 
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
        if (window.pageYOffset > 50) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
  }, []);
  return <>
      <Navbar/>
      <Outlet/>
      <Footer/>
      <a href="#" className={`scroll-top btn-hover ${isScrolled ? 'scrolled' : 'd-none'}`}>
        <i className="arrow up"></i>
    </a>
     </>
  
}
