import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';

export default function SearchQuestion(QuestionsList) {
  const { t, i18n } = useTranslation()
  const [searchQuery, setSearchQuery] = useState('');

  const array = QuestionsList.QuestionsList
  const array3 =
    [
      {
        "code": "201",
        "arName": "الشركة",
        "enName": "Company",
        "arFullName": "الإدارة - الشركة",
        "enFullName": "Admin - Company",
        "questionsAndAnswers": [
        {
        "arQuestion": "كيف أقوم بإضافة و تحيث بيانات الفرع",
        "arAnswer": "اختر من القائمة الرئيسية الشركة ثم اختر الفروع  سيقوم النظام بعرض جميع بيانات الفروع  قم بالضغط على  ( + )  سوف يقوم النظام بفتح صفحة جديدة لتعبئة بيانات فرع  جديد، وعند القيام بحفظ بيانات الفرع الجديد سيقوم النظام بفتح ملفات للمستندات ، و لتحديث بيانات الفرع قم باختيار الفرع  ستظهر بيانات الفرع للتحديث ملحوظة: (عند إيقاف الفرع لن يتمكن من إنشاء عقود تأجير جديدة، وسيتم إيقاف التعامل مع نقاط البيع التابعة للفرع  ، كذلك لايمكن حذف فرع  و مرتبط به نقاط بيع أو  سيارات ). ",
        "enQuestion": "How to add and update branch data?",
        "enAnswer": "Select \"company\" from the main menu, then select \"branches\". The system will display all the branches’ data. Press (+) and the system will open a new page to fill in the data for a new branch. When you save the new branch’s data, the system will open a file for the documents. To update the branch’s data, select the branch, and the branch data will appear for updating. Note: (When the branch is held, it will not enable creating new rental contracts, and hold the branch’s points of sale transactions. It is also not allowed to delete a branch that has existing points of sale or cars associated with it)."
        },
        {
        "arQuestion": "كيف أقوم بتحديث مستندات الشركة",
        "arAnswer": "اختر من القائمة الرئيسية الشركة ثم اختر المستندات  سيقوم النظام بعرض جميع بيانات المستندات التي لم تسجل أو المنتهية أو على وشك الانتهاء ، (  في حالة الرغبة بعرض البقية قم باختيار التصفية ) ، اختر المستند المحدد ستظهر لك صفحة تمكنك هذه الصفحة من تحديث بيانات أو حذف  المستند",
        "enQuestion": "How to update Company's documents?",
        "enAnswer": "Select \"company \" from the main menu, then select \"documents\". The system will display all data for documents that have not been registered, have expired, or are about to expire. (If you want to display the rest, select filtering. Then selectthe specific document. A page will appear enables you to update data or delete the document.)"
        },
        {
        "arQuestion": "كيف أقوم بإضافة او تحديث عقد",
        "arAnswer": "",
        "enQuestion": "How to create or update contract?",
        "enAnswer": ""
        },
        {
        "arQuestion": "كيف أقوم بإضافة او تحديث بيانات مالك",
        "arAnswer": "اختر من القائمة الرئيسية الشركة ثم اختر الملاك سيقوم النظام بعرض جميع بيانات الملاك قم بالضغط على ( + ) سوف يقوم النظام بفتح صفحة جديدة لتعبئة بيانات المالك الجديد ، و لتحديث بيانات المالك قم باختيار المالك ستظهر بيانات المالك للتحديث ملحوظة: (عند إيقاف المالك لن يتمكن من إنشاء عقود تأجير جديدة ترجع ملكيتها للمالك الموقوف،  كذلك لايمكن حذف مالك و لدية سيارات ). ",
        "enQuestion": "How to add or update owner data?",
        "enAnswer": "Select \"company\" from the main menu, then select \"owners\". The system will display all the owners’ data. Press (+) and the system will open a new page to fill in the new owner’s data. To update the owner’s data, select \"owner\" and the owner’s data will appear for updating. Note: (When the owner is held, it will not be able to create new rental contracts whose ownership belongs to the held owner, it is also not allowed to delete an owner who has cars.)"
        }
        ]
        },
        {
        "code": "202",
        "arName": "السيارات",
        "enName": "Cars",
        "arFullName": "الإدارة - السيارات",
        "enFullName": "Admin - Cars",
        "questionsAndAnswers": [
        {
        "arQuestion": "كيف أقوم بتحديث وثائق السيارة",
        "arAnswer": "اختر من القائمة الرئيسية السيارات ثم اختر الوثائق سيقوم النظام بعرض جميع بيانات وثائق السيارات التي لم تسجل أو المنتهية أو على وشك الانتهاء ، (  في حالة الرغبة بعرض البقية قم باختيار من خلال التصفية ) ، اختر وثيقة السيارة  المرغوبه ستظهر لك صفحة تمكنك هذه الصفحة من تحديث بيانات أو حذف  الوثيقة .",
        "enQuestion": "How to update car's documents?",
        "enAnswer": "Select \"Cars\" from the main menu, then select \"Documents Cars\". The system will display all data for car documents that have not been registered, expired, or are about to expire. (If you want to view the rest, select filtering. Then select the desired car document. A page will appear for you to update data or delete the document). "
        },
        {
        "arQuestion": "كيف أقوم بتحديث صيانة السيارة",
        "arAnswer": "اختر من القائمة الرئيسية السيارات ثم اختر الصيانة سيقوم النظام بعرض جميع بيانات بنود صيانة السيارات التي لم تسجل أو المنتهية أو على وشك الانتهاء ، (  في حالة الرغبة بعرض البقية قم باختيار من خلال التصفية ) ، اختر السيارة تحديث بيانات الصيانة ستظهر لك صفحة تمكنك هذه الصفحة من تحديث بيانات أو حذف  الصيانة .",
        "enQuestion": "How to update car's maintenance? ",
        "enAnswer": "Select \"Cars\" from the main menu, then select \"Maintenance\". The system will display all data on car maintenance items that have not been registered, have expired, or are about to expire. (If you want to view the rest, select filtering. Then select the car. To update maintenance data. A page will appear that enables you to update data or deleting maintenance data)."
        },
        {
        "arQuestion": "كيف أقوم بإضافة وحذف أسعار السيارات",
        "arAnswer": "",
        "enQuestion": "How to add and delete cars' prices? ",
        "enAnswer": ""
        },
        {
        "arQuestion": "كيف أقوم بإضافة و تحيث بيانات سيارة",
        "arAnswer": "اختر من القائمة الرئيسية السيارات ثم اختر بيانات السيارات سيقوم النظام بعرض جميع بيانات السيارات قم بالضغط على   ( + )  سوف يقوم النظام بفتح صفحة جديدة لتعبئة بيانات سيارة جديدة، وعند القيام بحفظ بيانات السيارة  الجديدة في الفرع الرئيسي ، كذلك سيقوم النظام  بفتح ملفات للوثائق و جدولة الصيانة الخاصة بالسيارة ، و لتحديث بيانات السيارة قم باختيار السيارة  ستظهر بيانات السيارة  للتحديث ملحوظة: (عند إيقاف السيارة  لن يتم تأجير السيارة  كذلك في حالة نقلها للصيانة وعند عرضها للبيع سيتم ايقاف عملية التأجير و عرضها بمنصة بيع السيارات ). ",
        "enQuestion": "How to add and update car data?",
        "enAnswer": "Select \"Cars\" from the main menu, then select \"Data Car\". The system will display all car data. Press (+) and the system will open a new page for filling out new car data. When you save the new car data in the main branch, the system will also open files for its documents and maintenance schedule. To update the car’s data, select \"car\" and the car’s data will appear for updating. Note: (When the car is held, the car will not be rented. Likewise, if it is transported for maintenance, and when it is offered for sale, the rental process will be stopped and placed on the car sales platform)."
        },
        {
        "arQuestion": "كيف أقوم بنقل سيارة من فرع إلى فرع",
        "arAnswer": "اختر من القائمة الرئيسية السيارات ثم اختر نقل سيارة  سيقوم النظام بعرض جميع بيانات السيارات التي حالتها نشط ،  اختر السيارة المراد نقلها ستظهر لك صفحة تمكنك من تحديد الفرع الجديد   .",
        "enQuestion": "How to transfer a car from and to a branch? ",
        "enAnswer": "Select \"Cars\" from the main menu, then select \"Car Transfer\". The system will display all data on cars whose status is active. Select the car you want to transfer. A page will appear that enables you to select the new branch."
        },
        {
        "arQuestion": "كيف أقوم بتغيير مالك السيارة ",
        "arAnswer": "اختر من القائمة الرئيسية السيارات ثم اخترتغيير مالك سيقوم النظام بعرض جميع بيانات السيارات التي حالتها نشط ،  اختر السيارة المراد  تغيير مالكها  ستظهر لك صفحة تمكنك من تحديد المالك الجديد  .",
        "enQuestion": "How to change car's owner?",
        "enAnswer": "Select \"Cars\" from the main menu, then select \"Owner Change\". The system will display all data on cars whose status is active. Select the car whose owner you want to change. A page will appear that enables you to specify the new owner."
        }
        ]
        }

    ]

  const handleSearch = (array) => {
    const words = searchQuery.split(' ').filter(word => word !== '');
    let matchQuestion 
    let matchSubItems 
    const filtered = array.filter(item => {
      if(document.body.dir === 'rtl' ){
        matchQuestion = words.some(word =>
          item.arName?.toLowerCase().includes(word.toLowerCase())
        );
         matchSubItems = item.questionsAndAnswers.some(questionsAndAnswers =>
          words.some(word => questionsAndAnswers?.arQuestion?.toLowerCase().includes(word.toLowerCase()))
        );
      }else{
        matchQuestion = words.some(word =>
          item.enName?.toLowerCase().includes(word.toLowerCase())
        );
         matchSubItems = item?.questionsAndAnswers?.some(questionsAndAnswers =>
          words.some(word => questionsAndAnswers?.enQuestion?.toLowerCase().includes(word.toLowerCase()))
        );
      }
    
      return matchQuestion || matchSubItems;
    });

    return filtered;
  };

  const renderAccordionItems = (array) => {
    if (searchQuery !== '') {
      const filteredItems = handleSearch(array);
      if (filteredItems.length !== 0) {
        return filteredItems.map((item, index) => (
          <AccordionItem key={index}>
            <h2>
              <AccordionButton className="main-tab py-4 my-2">
                <Box flex="1" textAlign={document.body.dir === 'rtl' ? 'right' : 'left'} >
                {document.body.dir === 'rtl' ? item.arName : item.enName}
                </Box >
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Accordion allowToggle>
                {item.questionsAndAnswers?.map((questionsAndAnswers, subIndex) => (
                  <AccordionItem key={subIndex}>
                    <h3>
                      <AccordionButton className="inner-tab py-4 my-2">
                        <Box flex="1" textAlign={document.body.dir === 'rtl' ? 'right' : 'left'} >
                        {document.body.dir === 'rtl' ? questionsAndAnswers.arQuestion :  questionsAndAnswers.enQuestion}
                        </Box >
                        <AccordionIcon />
                      </AccordionButton>
                    </h3>
                    <AccordionPanel pb={2}>
                      <p>{document.body.dir === 'rtl' ? questionsAndAnswers.arAnswer :questionsAndAnswers.enAnswer }</p>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </AccordionPanel>
          </AccordionItem>
        ));
      }else{
        return<div>
          <p id="errorMessage" className='error p-3 mt-3'> <Trans i18nKey="common-question-Error">هذا السؤال غير موجود يمكنك التواصل معانا الاجابة عن سؤالك</Trans></p>
        </div>

      }

    } else {
      return array?.map((item, index) => (
        <AccordionItem key={index} >
          <h2>
            <AccordionButton className="main-tab py-4 my-2">
              <Box flex="1" textAlign={document.body.dir === 'rtl' ? 'right' : 'left'} >
                {document.body.dir === 'rtl' ? item.arName : item.enName}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Accordion allowToggle>
              {item.questionsAndAnswers?.map((questionsAndAnswers, subIndex) => (
                <AccordionItem key={subIndex}>
                  <h3>
                    <AccordionButton className="inner-tab py-4 my-2">
                      <Box flex="1" textAlign={document.body.dir === 'rtl' ? 'right' : 'left'}>
                      {document.body.dir === 'rtl' ? questionsAndAnswers.arQuestion : questionsAndAnswers.enQuestion}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h3>
                  <AccordionPanel pb={2}>
                    <p> {document.body.dir === 'rtl' ? questionsAndAnswers.arAnswer:questionsAndAnswers.enAnswer}</p>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </AccordionPanel>
        </AccordionItem>
      ));
    }
  };

  return (
    <div>
      <div className="my-5">
        <div className="row gap-1 align-items-center justify-content-center">
          <div className="col-md-6 col-lg-10">
            <input
              type="search"
              className="form-control "
              id="searchInput"
              placeholder={document.body.dir === 'rtl'?"يمكنك البحث عن سؤالك هنا ...":"You can search for your question here..."}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}

            />
          </div>

        </div>
      </div>
      <h2 className="faq-title mb-4" > <Trans i18nKey="common-question-mangement">الأسئلة الشائعة عن النظام الإداري :</Trans> </h2>

      <Accordion allowToggle>
        {renderAccordionItems(array)}
      </Accordion>
      <h2 className="faq-title mb-3 mt-3" > <Trans i18nKey="common-question-mangement">الأسئلة الشائعة عن نظام الفروع :</Trans> </h2>
      <Accordion allowToggle>
        {renderAccordionItems(array3)}
      </Accordion>
    </div>
  )
}