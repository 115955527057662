
import axios from 'axios';
import SearchQuestion from './SearchQuestion';
import React, { useEffect, useState } from 'react';
import { useTranslation , Trans } from 'react-i18next';

export default  function CommonQuestions  () {
  const { t , i18n} = useTranslation()
  const [searchQuery, setSearchQuery] = useState('');
  const[QuestionsList , SetQuestionsList]=useState();
  async function GetQuestions() {

    try {
        const { data } = await axios.get('http://5.189.146.89/BnanApi/api/Questions/')
        console.log(data)
        SetQuestionsList(data)
    } catch (error) {
        console.log(error)
    }

}
useEffect(()=>{
  GetQuestions();
},[])
  // //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



  return (
    <div className="container tabb">
      <h2 className="text-center common-question-title"> <Trans i18nKey="common-question-title">مهمتنا مساعدتك </Trans>!   </h2>

      <div className="section pt-4">
   
     <SearchQuestion QuestionsList= {QuestionsList} searchQuery = {searchQuery}/>
     {/* <h2 className="faq-title my-4"  >  <Trans i18nKey="common-question-branches">   الأسئلة الشائعة عن نظام الفروع  </Trans>     </h2>
     <SearchQuestion accordionItems= {accordionItems2}/> */}


        <div>
        </div>

      </div>
    </div>
  );
}



